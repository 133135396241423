import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';
import { Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

import '../../generic-page.scss';

const CSDive50Page: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dive 48 - 50 guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_dive.png"
            alt="Dive 48 - 50"
          />
        </div>
        <div className="page-details">
          <h1>Dive 48 - 50</h1>
          <h2>Struggling with Dives 48-50? Then this guide is for you!</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Scavenger Dives (Dive Lvls 46-50) are some of the most difficult dive
          floors in the game. While the boss fight in Lvls 46 and 47 can still
          be cleared with a generic PVE team, Lvls 48-50 contain bosses and
          enemies who are both difficult to kill and have special mechanics.
        </p>
        <p>
          This guide will explain how to properly clear Lvls 48-50, starting
          with an explanation of certain Dive mechanics you should be aware of,
          before introducing two of the most common team comps used for the boss
          fight.
        </p>
        <p>
          As a side note, parts of this guide will be explained from a first
          person perspective of the author (TripBitShooter) to better aid
          explanations.
        </p>
        <SectionHeader title="Support system" />
        <h5>What are supports?</h5>
        <p>
          Employees who have been assigned as leaders in their respective ships
          will become available as support units in battle. In other words, the
          leaders of other teams can be deployed to help the current team in
          battle.
        </p>
        <p>
          These leader support units do not use deployment cost; instead, their
          deployment is time based. This timer itself is based on how much the
          units cost.
        </p>
        <h4>Order of supports</h4>
        <p>
          Supports are made available based on the order of your teams, shown
          below:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/dive_order.jpg"
          alt="Dive order"
        />
        <p>
          For example, if team 2 is currently in a battle, team 1’s leader
          support will be made available first, followed by team 3’s, and then
          finally team 4’s.
        </p>
        <p>
          However, if a team is defeated in battle (i.e. the ship goes boom),
          their leader support unit will not be available anymore.
        </p>
        <h4>Does the order of your supports matter?</h4>
        <p>
          It most definitely does. Different comps will require the usage of
          different leader support units and orders to best optimise the comp.
        </p>
        <SectionHeader title="Artifacts" />
        <p>
          Artifacts are items in Dives that can be obtained for special buffs
          and debuffs. It is important to choose your artifacts carefully, as
          some will give better benefits to your team than others.
        </p>
        <p>Examples of Good Artifacts (not exhaustive):</p>
        <ul>
          <li>Offense Reactor (Ally Counter ATK +10%) </li>
          <li>
            Offense Dual Reactor (Ally Ally Counter Ultimate Skill DMG +25%,
            Skill Haste +10%)
          </li>
          <li>Charging Cube (Ally Skill Haste +10%)</li>
          <li>HP Cube (Ally HP +15%)</li>
          <li>
            Resource Cube (Significantly increases Deployment Resource gain +5%)
          </li>
          <li>Critical Cube (Ally Units CRIT DMG +50%)</li>
        </ul>
        <p>
          There are also different versions of the same artifact. For example,
          the Critical Cube has another version that only buffs CRIT DMG by 20%,
          while the Offense Reactor has another version that only buffs Counter
          ATK by 5%. Variants of the same artifact can appear within the same
          run, even if you already selected one of them, making it really easy
          to stack useful buffs.
        </p>
        <SectionHeader title="Save-scumming bug" />
        <p>
          As of 07/4/2023, you are able to retry any fight in a Dive by
          full-quitting the game and waiting for 10 minutes before logging back
          in. This is usually used to retry the boss fight if something goes
          wrong.
        </p>
        <p>Pros</p>
        <ul>
          <li>
            Saves Dive Permits (these will not be refunded if you quit or fail
            the Dive)
          </li>
          <li>Saves the artifacts in the current dive</li>
          <li>No need to fight the prior nodes again</li>
        </ul>
        <p>Cons</p>
        <ul>
          <li>10 minutes of your life you are not getting back</li>
          <li>
            Could possibly be treated as bug abusing and lead to a ban - so do
            it at your own risk
          </li>
        </ul>
        <h4>How do I know if I did it correctly?</h4>
        <p>
          If you enter the game after 10 minutes have passed, you will be back
          at the lobby screen and you can choose to do other things or retry the
          dive.
        </p>
        <p>
          If you enter too early, you will be loaded directly back into the
          Dive, and will continue the fight from the exact time you quit. If
          this is the case, you will have to quit the game and wait 10 or more
          minutes.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="Hospital Team Comp" />
        <h4>Foreword</h4>
        <p>
          This is a team based around using an extremely tanky frontliner
          (usually an awakened defender) and supporting them with healers (hence
          the name, “Hospital”).
        </p>
        <h4>Generic team comp</h4>
        <div className="employee-container">
          <Employee slug="awakened-hilde" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="claudia-nelson" mode="icon" enablePopover />
          <Employee slug="nanahara-chinatsu" mode="icon" enablePopover />
        </div>
        <p>
          This is just a very rough overview of the kind of units you want for
          the Hospital Team. There are other primary frontliner choices which
          you can use in place of A.Hilde, and are in general better than her,
          such as:
        </p>
        <div className="employee-container">
          <Employee slug="awakened-jake" mode="icon" enablePopover />
          <Employee slug="breakbeat-alex" mode="icon" enablePopover />
          <Employee slug="hero-blossom-kaci" mode="icon" enablePopover />
          <Employee slug="awakened-joo-shiyoon" mode="icon" enablePopover />
        </div>
        <h5>Other recommended units</h5>
        <div className="employee-container">
          <Employee slug="ministra" mode="icon" enablePopover />
        </div>
        <p>Ministra (HIGHLY RECOMMENDED):</p>
        <ul>
          <li>Extremely high survivability</li>
          <li>Very good AOE damage</li>
          <li>
            100% anti-heal on her special skill to prevent bosses from healing
          </li>
          <li>
            Ability to summon Spira (when her hp drops below 60%) for emergency
            tanking and even more debuffs
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="rita-arsenico" mode="icon" enablePopover />
        </div>
        <p>Rita (HIGHLY RECOMMENDED):</p>
        <ul>
          <li>High AOE damage (especially due to her anti-mech dmg passive)</li>
          <li>True damage on enhanced attack</li>
          <li>Cheap cost</li>
          <li>
            Has anti-mech dmg res from her passive for better survivability
          </li>
          <li>
            Buff removal and buff locking (cannot gain new buffs) specifically
            for enemy mechs
          </li>
          <li>
            Anti-heal and ASPD debuffs on all enemies, with increased values for
            mechs.
          </li>
          <li>
            The non-boss Rojo is not a mech type, so Rita’s execute does not
            work on him
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="kim-sobin" mode="icon" enablePopover />
        </div>
        <p>Kim Sobin (VERY GOOD CHOICE):</p>
        <ul>
          <li>
            Anti-mech dmg passive go brrrr (fun fact: both her anti-mech dmg and
            anti-soldier passives work on Rojo, so she gets insane amounts of
            damage on him assuming she does not die to some random AOE attack).{' '}
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="sylvia-lena-cooper" mode="icon" enablePopover />
        </div>
        <p>Sylvia Lena Cooper (RECOMMENDED ONLY FOR OLIVIE COMPS):</p>
        <ul>
          <li>Ability to proc Olivie operator</li>
          <li>
            Can stun the E1 boss and other enemies (except Rojo, who is stun and
            hitstun immune)
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="awakened-seo-yoon" mode="icon" enablePopover />
        </div>
        <p>Awakened Seo Yoon (VERY GOOD CHOICE IF YOU DO NOT HAVE MINISTRA):</p>
        <ul>
          <li>High survivability</li>
          <li>Good AOE damage</li>
        </ul>
        <div className="employee-container">
          <Employee slug="nicole" mode="icon" enablePopover />
        </div>
        <p>Nicole (VERY GOOD CHOICE):</p>
        <ul>
          <li>
            15% Max HP dmg on her special is very good at killing non-boss Rojo
            in Lvl 48 and 50 (Despite her special skill description saying the
            15% Max HP dmg is limited to Replacer and CO enemies, this is not
            true. It affects all units except bosses and ships)
          </li>
          <li>
            -90% SPD and skill seal debuffs to defenders within a certain
            distance
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="lucrecia" mode="icon" enablePopover />
        </div>
        <p>Lucrecia (VERY GOOD CHOICE):</p>
        <ul>
          <li>Def shred on special skill</li>
          <li>
            Immortality buff on deploy to the 3 frontmost allies can help to
            stabilise the comp at the start of the fight, before all healers are
            deployed
          </li>
          <li>
            ASPD -100% (capped to -90% in-game) on ult for all enemies except
            for bosses
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="ifrit" mode="icon" enablePopover />
        </div>
        <p>Ifrit (DECENT CHOICE):</p>
        <ul>
          <li>Very high single-target damage</li>
          <li>
            Can still be used in D50, even though the E1 boss in there will eat
            all mechs on the field, including your own, periodically to heal
            itself. Best used with Ministra, for her 100% anti-heal debuff that
            prevents E1 from healing completely.
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
        </div>
        <p>Old Admin Rifleman (VERY GOOD CHOICE):</p>
        <ul>
          <li>The best 2-cost dps you can get for fighting defenders. </li>
          <li>
            Survivability is an issue, so be careful with how you use them.
          </li>
        </ul>
        <div className="employee-container">
          <Employee slug="gaeun" mode="icon" enablePopover />
        </div>
        <p>Gaeun (VERY GOOD CHOICE):</p>
        <ul>
          <li>Good AOE Damage </li>
          <li>Stuns, hitstuns, and large area CC</li>
          <li>Good survivability due to high stats</li>
          <li>Low cost</li>
        </ul>
        <div className="employee-container">
          <Employee slug="spira" mode="icon" enablePopover />
        </div>
        <p>Spira (GOOD CHOICE):</p>
        <ul>
          <li>Very tanky</li>
          <li>Tons of debuffs </li>
          <li>Even more anti-heal debuffs</li>
        </ul>
        <div className="employee-container">
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
        </div>
        <p>Rosaria (GOOD CHOICE):</p>
        <ul>
          <li>High AOE damage</li>
        </ul>
        <div className="employee-container">
          <Employee
            slug="awakened-yuna-springfield"
            mode="icon"
            enablePopover
          />
        </div>
        <p>Awakened Yuna (GOOD CHOICE):</p>
        <ul>
          <li>Hiding behind A. Hilde, she simply will not die</li>
          <li>Hp burn against enemies which are not bosses</li>
          <li>Good AOE damage</li>
        </ul>
        <div className="employee-container">
          <Employee slug="awakened-lee-sooyeon" mode="icon" enablePopover />
        </div>
        <p>Awakened Lee Soo Yeon (VERY GOOD CHOICE)</p>
        <ul>
          <li>Good AOE damage</li>
          <li>Generates her own shields to protect herself</li>
          <li>CC on special skill and large AOE attack on ult</li>
          <li>
            Gives all non-awakened Ranger Counters immunity to hitstun from
            special or lower skills and 30% additional skill haste
          </li>
        </ul>
        <p>
          These units do not have to be on your main team, because they can also
          be the leader supports on your other teams.{' '}
        </p>
        <h4>Ships</h4>
        <p>Lake Superior or Gleipnir Armor</p>
        <h4>Operator</h4>
        <p>
          Any operator with AOE Damage Res or Ground Damage Res (not mandatory,
          but HIGHLY recommended). If possible, using Olivie (with Sylvia) or
          Kim Hana will further ease your run.{' '}
        </p>
        <h4>Game plan</h4>
        <ul>
          <li>
            Use Awakened Hilde's or Jake’s absurd tankiness to soak up damage,
          </li>
          <li>Supports will heal A. Hilde so she doesn't die,</li>
          <li>DPS to deal damage and kill things.</li>
        </ul>
        <p>Here is an example of a team that can clear Dive 48-50 on auto:</p>
        <StaticImage
          src="../../../images/counterside/generic/dive_n_1.jpg"
          alt="D50 team"
        />
        <p>
          If you are unable to clear on auto, you can still do it on manual.
          Here are some things you should note per stage while playing on
          manual:
        </p>
        <h5>Strategy for Dive 48 (manual)</h5>
        <p>
          There’s really not much to say here. Just be aware of Rojo’s special
          (which can hit units in the backline) and Chronos Beta’s special (will
          stun any unit it hits, which is usually just the frontliners). The
          only thing you really have to pay attention to is how much health your
          frontliner has.
        </p>
        <p>
          Here is a video of my Dive 48 run on auto. Use it as reference for the
          mechanics of the stage:
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="Ohh91C06v0M" />
          </Col>
        </Row>
        <h5>Strategy for Dive 49 (manual)</h5>
        <p>
          The moment the match starts, it is recommended to deploy Awakened
          Hilde/Jake as far forward as possible. This blocks the enemy Black
          Tail (the black helicopter) from flying too far forward, as it will
          immediately target your frontline tank. Allowing it to fly too far
          forward may allow it to shoot your healers in the backline instead,
          with Evelyn being particularly vulnerable due to how fast she flies.{' '}
        </p>
        <p>
          For Rojo’s first ult, it is recommended to bait Rojo into ulting
          backwards using Ingrid or any other tanky unit who can be deployed
          anywhere on the field. This is because Rojo’s ult causes him to charge
          forward, and if you do not redirect this, he can end up directly on
          your healers and kill them all.{' '}
        </p>
        <p>
          If you cannot kill him before his second ult, you should still aim to
          have him below 2/3hp by then. This will allow you to just redeploy
          your frontline tank behind him to bait his second ult backwards.{' '}
        </p>
        <p>
          Once again, here is a video of my own run, also on auto. Notice how
          Rojo’s first ult actually does put him directly on my Harim, but a
          combination of lucky timing and tons of heals + shields allowed her to
          survive until Jake could return to tank for the team. Having a second
          frontliner (like Rearm Orca or Chifuyu) available to tank for the
          backline after Rojo’s ult is recommended if you choose not to bait it
          in the opposite direction.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="7CbciN6-t_U" />
          </Col>
        </Row>
        <h5>Strategy for Dive 50 (manual)</h5>
        <p>
          Dive 50 is similar to Dive 48, except the enemies are now lvl 140, and
          Chronos Beta is now replaced with Evolved One, who has an actual ult.
          The most significant difference here is probably the addition of lvl
          140 Arachnes to the enemy units, who deal tons of damage.{' '}
        </p>
        <p>
          Dealing with Rojo is the same as Dive 48: Block him with a frontline
          unit and kill him ASAP. Beware of Evolved One’s enhanced basic attack,
          which sends a swarm of missiles to the furthest unit within range and
          deals AOE dmg around. You can see my Claudia die to this in the video
          shared below.{' '}
        </p>
        <p>
          As for the ult, you have two choices: have your entire team facetank
          it using shields, or bait it backwards like Dive 49. Both strategies
          are equally viable here.{' '}
        </p>
        <p>
          After the first ult goes off, the run has probably already stabilised.
          Just keep an eye on your units and be ready to redeploy those who die
          (it is pretty normal for healers to die occasionally).{' '}
        </p>
        <p>
          Here is yet another video by yours truly, showing my Dive 50 run on
          auto. Midway through, my Awakened Jake dies due to (unfortunately)
          being knocked away from my healers by Evolved One’s ult, but my leader
          support Rearm Orca was deployed in time to pick up the slack until
          Awakened Jake was redeployed.{' '}
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="4HJEaX-wpSI" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="Videos with Alternate Team Comps" />
        <p>
          Here are two additional videos depicting Hospital Team Comps using
          Awakened Shiyoon and Rearmed Alex.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="gqr5sE6feuU" />
          </Col>
        </Row>
        <p>
          Shiyoon is great here due to his ability to counter incoming enemy
          attacks, while also relying on both his Perfect Eva self buffs and his
          naturally high tankiness to just eat incoming damage without dying.{' '}
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="1oqx5xnEk0c" />
          </Col>
        </Row>
        <p>
          The main strength of using Rearmed Alex or Rearmed Kaci is their much
          lower cost, compared to awakened frontliner options. This allows you
          throw out your supports and backliners faster, which helps to
          stabilise the run sooner.{' '}
        </p>
        <SectionHeader title="Immortality Team Comp" />
        <h4>Foreword</h4>
        <p>
          A team based around two frontliners, Rearm Esterosa and Veronica, who
          have immortality in their kit. This allows them to tank tons of damage
          without needing healing, while also being cheap enough to be
          constantly redeployed.
        </p>
        <h4>Team Comp</h4>
        <div className="employee-container">
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="kestrel-xiao-lin" mode="icon" enablePopover />
          <Employee slug="near-astraea-esterosa" mode="icon" enablePopover />
          <Employee slug="ingrid-johanna" mode="icon" enablePopover />
        </div>
        <p>
          Karin Wong, Replacer Queen and base Xiao Lin (if you do not have
          Kestrel Xiao Lin) are other options for snipers. Rearm Esterosa can
          also be replaced by Veronica, but Veronica is a lot less tanky. Just
          be aware that the team can only consist of 4 units, so that you always
          have Ingrid on hand for baiting ults in the opposite direction.
        </p>
        <h4>Ships</h4>
        <p>New Ohio (best) / Enterprise / Kamiizumi</p>
        <h4>Operators</h4>
        <p>Any operator with: +CDMG% (Best) / +ASPD / Anything</p>
        <h4>Strategy</h4>
        <ul>
          <li>
            Use Rearm Esterosa/Veronica to tank tons of damage, thanks to their
            immortality mechanics, and redeploy them before the immortality buff
            goes away to avoid the death deploy delay. Repeat ad nauseam.
          </li>
          <li>
            Snipers deal damage from afar (Important as E1 has an enhanced
            attack which can reach backline units that are too close).
          </li>
          <li>
            Ingrid is used to redirect enemy ults in the wrong direction to
            protect your snipers.
          </li>
          <li>
            Use ship skills to give yourself some breathing room. New Ohio is
            the best for this as it can delay bosses’ skills.
          </li>
        </ul>
        <h5>Alternate Strategy for Dive 49</h5>
        <p>
          There exists an alternate strategy for Dive 49, where you place Rearm
          Esterosa/Veronica alone as far forward as possible, and allow Rojo to
          use his ult ASAP (do not delay it with Ohio ship skills). After Rojo
          uses his ult, redeploy Esterosa/Veronica behind him to turn him
          around, before deploying rangers near your ship to rapidly burst him
          down.{' '}
        </p>
        <p>
          Linked here is a video run of this alternate strategy. While this was
          recorded before the Origin update, the video is still valid for
          demonstrating stage mechanics because they remained the same.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="zteWJuHYZmk" />
          </Col>
        </Row>
        <h5>Further Notes</h5>
        <p>
          Ministra can be used to block healing. She eventually dies (due to
          being hit by backline targeting attacks from Evolved One), but the
          most important thing is that she prevents the boss from healing, which
          significantly decreases runtime.{' '}
        </p>
        <p>
          Linked below is a video by Fwuq, who shows how he used Rearm Esterosa
          + Snipers + Ministra to clear Dive 50 effectively.{' '}
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="glV9AOaPy_Y" />
          </Col>
        </Row>
        <p>
          In addition, notice how on Evolved One’s first ult, the ult animation
          started facing Fwuq’s ship, but his snipers did not die. This is
          because where the ult hits is not determined by which direction
          Evolved One is facing when it starts, but is instead determined by a
          red targeting marker (you can see the red marker on his Ingrid).{' '}
        </p>
        <h5>Closing Remarks</h5>
        <p>
          The original version of this guide was not written by me; I only wrote
          the Hospital Team Comp section. I was not even an official Prydwen
          team member back then. As of the most recent update of this guide
          (07/4/2023), that was more than a year ago. How time flies...
        </p>
        <p>
          However, I now remain the only person maintaining the guide, with many
          of the sections having undergone significant rewrites under my
          supervision. This guide is also really close to my heart, as it was
          the first thing I ever contributed to Prydwen.{' '}
        </p>
        <p>
          If you are having trouble clearing Dive lvls 48-50, I hope this guide
          was able to help you. Do remember to take your time, especially if you
          are a newer player and do not have gears good enough to clear the
          Dives.{' '}
        </p>
        <p>That will be all from me. See you in another guide!</p>
        <p>~TripBitShooter</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default CSDive50Page;

export const Head: React.FC = () => (
  <Seo
    title="Dive 48-50 guide | Counter Side | Prydwen Institute"
    description="Guide for the hardest Dive 48-50 stages mode in Counter Side."
  />
);
